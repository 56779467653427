import { Hero, FileInput, Card, Form, Button, Progress } from "react-daisyui";
import { useRef, useState, useCallback, useEffect } from "react";
import { RemoteRunnable } from "langchain/runnables/remote";

const HeroSection = ({ setData, setAlerts }) => {
    const fileInput = useRef(null);
    const count = useRef(0);
    const max = useRef(0);
    const [processing, setProcessing] = useState(false);
    const remoteChain = useRef();

    const handleSetAlerts = useCallback(
        (alert) => {
            setAlerts((prev) => [...prev, alert]);
        },
        [setAlerts]
    );

    useEffect(() => {
        remoteChain.current = new RemoteRunnable({
            url: "https://octo-nc-omni-product-generator-langserve-piepovbtcq-uc.a.run.app/product",
        });
    }, []);

    const makeQuery = async (product) => {
        let response = await remoteChain.current.invoke({
            product: JSON.stringify(product),
        });

        return response.content.replace("```json\n", "").replace("\n```", "");
    };

    const handleFileUpload = useCallback(
        async (e) => {
            e.preventDefault();
            const file = fileInput.current.files[0];
            if (file) {
                if (!file.name.endsWith(".json")) {
                    handleSetAlerts({
                        type: "error",
                        message: "Invalid file type. Please select a JSON file.",
                    });
                    return;
                }

                setData([]);
                setProcessing(true);
                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = async (evt) => {
                    const json = JSON.parse(evt.target.result);
                    max.current = json.length;
                    for (let i = 0; i < json.length; i++) {
                        let message = json[i];
                        console.log(message);
                        let response = await makeQuery(message);
                        try {
                            let jsonResponse = JSON.parse(response);
                            count.current += 1;
                            setData((prev) => [...prev, jsonResponse]);
                        } catch (error) {
                            console.error(error);
                        }
                    }
                    setProcessing(false);
                };
            } else {
                handleSetAlerts({ type: "error", message: "No file selected" });
            }
        },
        [handleSetAlerts, setData]
    );

    return (
        <Hero className="flex flex-col mt-5 p-16 bg-slate-300 shadow-lg rounded">
            <Hero.Content className="flex lg:flex-row-reverse max-w-full">
                <div className="w-2/6 flex flex-col">
                    <Card className="shadow-2xl bg-base-100 w-full h-64" bordered="false">
                        <Card.Body className="p-5">
                            <Form>
                                <Form.Label title="Upload Files" className="font-bold" />
                                <div className="flex flex-col items-center">
                                    <FileInput
                                        className="mb-4 w-4/5"
                                        color="primary"
                                        ref={fileInput}
                                    />
                                    <Button
                                        loading={processing}
                                        rounded="true"
                                        onClick={handleFileUpload}
                                        className="inline-block rounded-full px-6 text-md uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#14a44d] transition duration-150 ease-in-out"
                                        color="success"
                                        wide="true"
                                    >
                                        {" "}
                                        Submit
                                    </Button>
                                </div>
                                <div className="flex flex-col mx-auto">
                                    <Progress
                                        value={count.current}
                                        max={max.current}
                                        color="secondary"
                                        className="mt-5"
                                    />
                                    {processing && (
                                        <span>
                                            {count.current} of {max.current} products processed
                                        </span>
                                    )}
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
                <div className="text-center lg:text-left w-3/6">
                    <h2 className="text-4xl font-bold">
                        <span className="underline decoration-red-600 decoration-4">
                            From jargon to joy:{" "}
                        </span>
                        Transform dry specs into dazzling descriptions with Google Gemini.
                    </h2>
                    <p className="py-3">
                        Transform tedious product specifications into captivating retail
                        listings with Gemini Pro's innovative product description generator.
                        This demo showcases the remarkable ability of Gemini Pro to
                        understand technical product details and generate attention-grabbing
                        headlines and descriptions tailored for retail websites. By
                        automating this often time-consuming task, businesses can streamline
                        their product listing processes, saving valuable resources and
                        ensuring that their product descriptions are both informative and
                        persuasive. This technology has the potential to revolutionize the
                        way products are presented online, making listings more attractive
                        to potential customers.
                    </p>
                    <p>
                        <a href="/products.json" className="underline">Right Click Here</a> to save input file.
                    </p>
                </div>
            </Hero.Content>
        </Hero>
    );
};

export default HeroSection;

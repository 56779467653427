import '../App.css';
import ProductCard from "../components/ProductDescription/ProductCard";
import { useState } from "react";
import Title from "../components/ProductDescription/Title";
import HeroSection from "../components/ProductDescription/Hero";
import ToastAlert from '../components/Toast';
import ProductDetails from '../components/ProductDescription/ProductDetails';


function ProductDescription() {
    const [data, setData] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [productModal, setProductModal] = useState(false);
    const [currentProduct, setCurrentProduct] = useState({});

    const handleProductDetails = (index) => {
        setCurrentProduct(data[index]);
        setProductModal(!productModal);
    };

    return (
        <>
            <ToastAlert alerts={alerts} setAlerts={setAlerts} />
            <Title />
            <HeroSection setData={setData} alerts={alerts} setAlerts={setAlerts} setCurrentProduct={setCurrentProduct}/>
            <div className="w-full mx-auto mt-5 md:w-11/12">
                <div className="grid grid-cols-2 gap-5 mb-10">
                    {
                        data.map((item, item_id) => (
                            <ProductCard key={item_id} item_id={item_id} item={item} toggleModal={handleProductDetails} />
                        ))
                    }
                </div>
            </div>
        </>
    );
}

export default ProductDescription;

import { Hero, Card, Button } from "react-daisyui";
import React from "react";
import CDWLogo from "../assets/img/CDWLogo.svg";
import GoogleAI from "../assets/img/google-ai.png";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Home = () => {
    return (
        <div className="App">
            <div className="flex flex-col items-center justify-center pt-5">
                <img
                    src={CDWLogo}
                    alt="CDW Logo"
                    width={112}
                    height={112}
                    className="w-28 mx-auto mb-2"
                />
                <h1 className="lg:text-5xl font-bold sm:text-lg md:text-3xl text-primary font-sans capitalize">
                    Google AI Demos
                </h1>
            </div>
            <Hero className="flex flex-col mt-5 p-16 bg-slate-300 shadow-lg rounded">
                <Hero.Content className="flex lg:flex-row-reverse max-w-full">
                    <div className="w-2/6 flex flex-col">
                        <Card
                            className="shadow-2xl bg-base-100 w-full h-64"
                            bordered="false">
                            <Card.Body className="p-8 mx-auto flex justify-center items-center">
                                <img src={GoogleAI} alt="Google AI Logo" className=" mx-auto" />
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="text-center lg:text-left w-3/6 px-5">
                        <h2 className="text-3xl font-bold">
                            <span className="underline decoration-red-700 decoration-4">
                                Real World{" "}
                            </span>
                            Generative AI: Use Case Demos
                        </h2>
                        <p className="py-3 px-1 text-justify">
                            Google and CDW offer a powerful partnership to help customers
                            harness the potential of AI to solve real-world challenges.
                            Google's cutting-edge Gemini Pro models provide the foundation for
                            innovative generative AI solutions, while CDW delivers expertise
                            in implementation and integration. Together, they can guide
                            businesses through AI strategy, development, and deployment. This
                            collaboration empowers customers to streamline operations, enhance
                            customer experiences, and unlock new opportunities through the
                            transformative power of AI.
                        </p>
                    </div>
                </Hero.Content>
            </Hero>
            <div className="p-2">
                <h1 className="lg:text-5xl font-bold sm:text-lg md:text-3xl font-sans capitalize p-8">
                    Choose a Demo
                </h1>
                <span>Please note that these demos are running in a containerized environment on Google Cloud Run. If they have not been used they go to sleep. Once you select a demo there maybe a slight delay in them loading</span>
                <div className="grid grid-cols-2 gap-6 p-5 w-full mx-auto">
                    <Card>
                        <Card.Body>
                            <div className="items-center justify-center pt-5">
                                <h2 className="lg:text-3xl font-bold sm:text-lg md:text-3xl text-primary font-sans capitalize">
                                    OmniChannel Intelligence
                                </h2>
                                <p className="py-3">
                                    The OmniChannel Intelligence demo showcases the power of Google AI
                                    to derive valuable insights from customer messages,
                                    regardless of the channel they were sent through. This demo
                                    demonstrates the potential of Google Gemini to revolutionize
                                    the way businesses interact with their customers.
                                </p>
                                <Link to="/omnichannel">
                                    <Button className="btn btn-primary text-white">
                                        Go to OmniChannel
                                    </Button>
                                </Link>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <div className="items-center justify-center pt-5">
                                <h2 className="lg:text-3xl font-bold sm:text-lg md:text-3xl text-primary font-sans capitalize">
                                    Product Description Generator
                                </h2>
                                <p className="py-3">
                                    Google Gemini Pro is a powerful tool that can transform dull
                                    technical specifications into engaging and persuasive
                                    retail-ready compositions. By providing the large language
                                    models with technical specifications, they can generate
                                    captivating product descriptions that highlight
                                    customer-centric benefits and evoke emotional connections.
                                </p>
                                <Link to="/product-description">
                                    <Button className="btn btn-primary text-white">
                                        Go to Product Description
                                    </Button>
                                </Link>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <div className="items-center justify-center pt-5">
                                <h2 className="lg:text-3xl font-bold sm:text-lg md:text-3xl text-primary font-sans capitalize">
                                    Financial Services Bot
                                </h2>
                                <p className="py-3">
                                    Generative AI LLMs can be used for MRKL to aide in the
                                    financial services industry by providing various solutions
                                    such as content generation and summarization for financial
                                    reports, newsletters, and marketing campaigns. They can also
                                    be used for data analysis and forecasting for financial
                                    trends, risks, and opportunities. Additionally, natural
                                    language processing and understanding can be used for customer
                                    service, compliance, and sentiment analysis. Robotic process
                                    automation can also be used for streamlining workflows,
                                    reducing errors, and increasing efficiency.
                                </p>
                                <Link to="https://octo-nc-google-financebot-chainlit-piepovbtcq-uc.a.run.app/">
                                    <Button className="btn btn-primary text-white">
                                        Go to Financial Services Bot
                                    </Button>
                                </Link>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <div className="items-center justify-center pt-5">
                                <h2 className="lg:text-3xl font-bold sm:text-lg md:text-3xl text-primary font-sans capitalize">
                                    Retrieval-Augmented Generation
                                </h2>
                                <p className="py-3">
                                    Retrieval augmented generation (RAG) is a powerful technique
                                    that can be used to create bots and automate processes with
                                    LLMs and Google Gemini. RAG combines the power of large
                                    language models with the precision of database retrieval to
                                    generate responses grounded in relevant information. This
                                    technique can be used to create chatbots that can answer
                                    questions and provide information to users in a natural and
                                    conversational manner. Additionally, RAG can be used to
                                    automate processes such as data analysis, content generation,
                                    and customer service, reducing the need for manual
                                    intervention and increasing efficiency.
                                </p>
                                <Link to="https://octo-nc-google-gemini-rag-demo-piepovbtcq-uc.a.run.app/">
                                    <Button className="btn btn-primary text-white">
                                        Go to RAG Demo
                                    </Button>
                                </Link>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <div className="items-center justify-center pt-5">
                                <h2 className="lg:text-3xl font-bold sm:text-lg md:text-3xl text-primary font-sans capitalize">
                                    Interactive Avatar
                                </h2>
                                <p className="py-3">
                                    This demo is an interactive avatar that can be used to provide
                                    information and answer questions in a conversational manner.
                                    It is built on the Google Cloud Platform and leverages both
                                    Langchain and Google Gemini to generate responses. The avatar
                                    can be customized to suit different use cases and industries,
                                    providing a unique and engaging experience for users. It can
                                    be used for customer service, product recommendations, and
                                    information retrieval, among other applications.
                                </p>
                                <Link to="https://avatar-gcp.intelligentcx.io">
                                    <Button className="btn btn-primary text-white">
                                        Go to Avatar Demo
                                    </Button>
                                </Link>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default Home;
